import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes } from 'react-router-dom';
import { renderRoute, rouets } from './config/routes';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {rouets.map((route, i) => renderRoute(route, i))}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
