import { ImageUploader } from "antd-mobile"
import api from "../config/api";
import OSS from "ali-oss";
import dayjs from "dayjs";
import { v4 } from "uuid";
import getLang from "../config/lang";
import { UploadOutline } from 'antd-mobile-icons'

export default ({ value, onChange, currency }) => {


    const upload = async (file) => {

        const { code, data } = await api("api/chat/upload/sts").get({});

        if (code != 200) {
            throw new Error("upload fail");
        }

        const client = new OSS({
            region: data.region,
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.secretAccessKey,
            stsToken: data.securityToken,
            bucket: data.bucket
        });

        // file.name
        const name = `${file.name}`;
        const fileExt = name.substring(name.lastIndexOf("."), name.length).toLowerCase();
        const fileName = `/upload/image/${dayjs().format("YYYY/MM/DD")}/${v4().replaceAll("-", "")}${fileExt}`
        console.log("fileName=>", fileName);
        const result = await client.put(fileName, file, {});

        if (result?.res?.status == 200) {

            return {
                url: result.url
            }
        }

        throw new Error("upload fail");

    }

    const lang = getLang(currency);

    return (
        <ImageUploader
            // multiple={false}
            value={value ? [{ url: value }] : []}
            maxCount={1}
            onChange={items => {
                console.log("items=>", items);
                if (items.length == 0) {
                    onChange(null);
                } else {
                    onChange(items[0].url)
                }

            }}
            upload={upload}
        >
            <div style={{ width: 80, height: 80, display: "flex",alignItems:"center",flexDirection:"column",justifyContent:"center",border:"1px solid #ccc" }}>
                <UploadOutline fontSize={40} color='var(--adm-color-primary)' />
                <div>
                   
                   
                    <a>{lang("点击上传")}</a>
                </div>
            </div>


        </ImageUploader>
    )
}