
const lang: any = {
    "THB":
    {
        "订单号": "หมายเลขคำสั่งซื้อ",
        "点击获取收款账号": "คลิกเพื่อรับบัญชีการชำระเงิน",
        "金额": "จำนวนเงิน",
        "复制": "คัดลอก",
        "银行": "ธนาคาร",
        "姓名": "ชื่อ",
        "卡号": "หมายเลขบัตร",
        "上传付款凭证": "อัปโหลดหลักฐานการโอนเงินผ่านธนาคาร",
        "聊天": "แชท",
        "请输入消息": "กรุณาใส่ข้อความ",
        "发送": "ส่ง",
        "订单已过期": "คำสั่งซื้อหมดอายุ",
        "支付成功": "ชำระเงินสำเร็จ",
        "提示": "แจ้งเตือน",
        "获取账号失败，请重试": "ไม่สามารถรับบัญชี กรุณาลองใหม่",
        "确定": "ยืนยัน",
        "复制成功": "คัดลอกสำเร็จ",
        "点击获取付款信息": "เริ่มชำระเงิน",
        "获取失败，请重试": "ล้มเหลว โปรดลองอีกครั้ง",
        "下载二维码": "ดาวน์โหลดรหัส QR",
        "点击上传": "อัปโหลด",
        "付款完成后请上传您的银行转账凭证": "กรุณาอัปโหลดใบเสร็จการโอนเงินธนาคารของคุณหลังจากการชำระเงินเสร็จสิ้น",
        "复制账号和金额转账": "คัดลอกหมายเลขบัญชีและจำนวนเงินเพื่อโอน",
        "不要私自保存收款账号": "กรุณาอย่าบันทึกหมายเลขบัญชีรับเงินด้วยตัวเอง",
        "请耐心等待客服确认": "กรุณารอการยืนยันจากฝ่ายบริการลูกค้าอย่างใจเย็น",
        "支付流程": "กระบวนการชำระเงิน",
        "截图或下载付款二维码": "บันทึกภาพหน้าจอหรือดาวน์โหลด QR โค้ด"
    },
    "VND": {

        "订单号": "Số đơn hàng",
        "点击获取收款账号": "Nhấn vào để nhận số tài khoản thanh toán",
        "金额": "Số tiền",
        "复制": "Sao chép",
        "银行": "Ngân hàng",
        "姓名": "Tên",
        "卡号": "Số thẻ",
        "上传付款凭证": "Tải lên chứng từ thanh toán",
        "聊天": "Trò chuyện",
        "请输入消息": "Vui lòng nhập tin nhắn",
        "发送": "Gửi",
        "订单已过期": "Đơn hàng đã hết hạn",
        "支付成功": "Thanh toán thành công",
        "提示": "Nhắc nhở",
        "获取账号失败，请重试": "Lấy tài khoản thất bại, vui lòng thử lại",
        "确定": "Xác nhận",
        "复制成功": "Sao chép thành công",
        "点击获取付款信息": "Nhấn để lấy thông tin thanh toán",
        "获取失败，请重试": "Lấy thông tin thất bại, vui lòng thử lại",
        "下载二维码": "Tải mã QR",
        "点击上传": "Nhấn để tải lên",
        "付款完成后请上传您的银行转账凭证": "Vui lòng tải lên biên lai chuyển khoản ngân hàng của bạn sau khi thanh toán xong",
        "复制账号和金额转账": "Sao chép số tài khoản và số tiền để chuyển khoản",
        "不要私自保存收款账号": "Không tự ý lưu số tài khoản nhận tiền",
        "请耐心等待客服确认": "Vui lòng chờ xác nhận từ dịch vụ khách hàng",
        "支付流程": "Quy trình thanh toán",
        "截图或下载付款二维码": "Chụp màn hình hoặc tải mã QR thanh toán",
        "留言":"Nội dung lời nhắn"

    }


}



type Func = (v: string) => string | any


const getLang: Func = (currency: string) => {


    var v: any = lang[`${currency}`];

    return (name: string) => {

        const s = v[`${name}`];

        return s ? s : name;
    }


}

export default getLang;