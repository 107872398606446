type ConfigEnv = {
  baseApi: string
}

const configValues: any = {
  development: {
    // baseApi: '//localhost:5302',
    baseApi: '//bapi.t.xpay.pw',
  },
  production: {
    baseApi: '//api.kabank.cc',
  },
}

const env: ConfigEnv = configValues[`${process.env.NODE_ENV}`]

export default env
