// let scoket:WebSocket = ;

import { FC, useEffect, useImperativeHandle, useRef } from "react";
import NScoket from "./scoket";

export interface Ws  {

    sendMessage:(message:Message)=>void
}

class WsImp implements Ws{

    private ws:NScoket | null|any;

    constructor(ws:NScoket|null|any){
        this.ws = ws;
    }


    sendMessage (message: Message) {
        this.ws?.send(message)
    };
    
}

type Message = {
    msgType:string,
    data:any
}

type ChatSocketProps = {

    onOpen:(ws:Ws)=>void,
    onMessage:(ws:Ws,message:Message)=>void,
    onClose:(ws:Ws,msg:string)=>void,
    url:string,
    children?:any,
    wsRef:any
}


const ChatSocket:FC<ChatSocketProps> = ({children,url,onClose,onOpen,onMessage,wsRef})=>{

    const ws = useRef<NScoket>();

    useImperativeHandle(wsRef,()=>{

        return new WsImp(ws.current);
    });


    
    useEffect(()=>{

        console.log("init websocket");

        ws.current = NScoket.create(url);

        ws.current.onOpen(()=>{

            onOpen(new WsImp(ws.current));

        });

        ws.current.onMessage((e:any)=>{
            if(e.data){
                onMessage(new WsImp(ws.current),JSON.parse(e.data));
            }

        });

        ws.current.onClose((e:any)=>{
            onClose(new WsImp(ws.current),e.reason)
        })



        ws.current.connect();

       

    },[]);



    return <>{children}</>

}



export default ChatSocket;