
export default class NScoket {

    ws = null;

    pause = false;

    started = false;

    timer = null;

    _events = {
        onOpen:()=>{},
        onError:()=>{},
        onClose:()=>{},
        onMessage:()=>{},
    };

    constructor(url){
        this.url = url;
    }

    getStatus = ()=>{
        if(this.ws){
            return this.ws.readyState;
        }
        return 0;
    }

    onOpen = (onOpen)=>{
        this._events["onOpen"] = onOpen;
        return this;
    }

    onMessage = (onMessage)=>{
        this._events["onMessage"] = onMessage;
        return this;
    }

    onClose = (onClose)=>{
        this._events["onClose"] = onClose;
        return this;
    }

    onError = (onError)=>{
        this._events["onError"] = onError;
        return this;
    }

    send = (msg)=>{
        if(this.ws && this.ws.readyState == 1){
            // console.log("ws=>",this.ws);
            if(typeof msg == "string"){
                this.ws.send(msg);
            }else{
                this.ws.send(JSON.stringify(msg));
            }
            
        }
        return this;
    }

    close = (reason)=>{
        this.started = false;
        if(this.ws){
            this.ws.close(1000,reason?reason:"客户端主动关闭");
        }
        //清除心跳定时器
        clearInterval(this.timer);
        return this;
    }


    connect = (fn)=>{
        this.ws = new WebSocket(this.url);

        this.started = true;
        if(this.timer){
            clearInterval(this.timer);
        }
        if(fn){
            this.onOpen(fn);
        }
        this.ws.onopen = ()=>{
            //发送心跳数据
           
            this._events.onOpen();
            this.timer = setInterval(()=>{

                if(this.started){
                    this.send({
                        msgType:"Heartbeat"
                    });
                }
               
            },10000);
        };
        this.ws.onmessage = (e)=>{
            if(this.pause){
                return;
            }
            this._events.onMessage(e);
        }
        this.ws.onclose = (e)=>{
            // console.log(e);
            this.started = false;
            console.log("close:",e,this.started);
            this._events.onClose(e);
           //重新连接服务器
           console.log("重新连接服务器...");
           
           setTimeout(()=>this.connect(fn),10000);

           
            // this._events.onError(e);
            
        }
        this.ws.onerror = (e)=>{
            console.log("error:",e,this.started);
            this._events.onError(e);
        
           
        }

     
        
        


        return this;

    }

    static  create(url){
        return new NScoket(url);
    }


}
