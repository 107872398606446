import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router"
import { Button, Card, ConfigProvider, DotLoading, ErrorBlock, Input, List, Modal, NavBar, Result, TextArea, Toast } from "antd-mobile";
import "./index.css";
import ChatSocket, { Ws } from "./ChatScoket";
import { log } from "console";
import api, { baseApiUrl } from "../../config/api";
import { PictureOutline } from "antd-mobile-icons";
import UploadImage from "../../public/UploadImage";
import dayjs from "dayjs";
import thTH from "antd-mobile/es/locales/en-US";
import getLang from "../../config/lang";
import generatePayload from "promptpay-qr";
import { QRCodeCanvas } from "qrcode.react";
import { toPng } from 'html-to-image';


type CurrencyFormatterProps = {
    amount: number,
    currency: string,

}

const CurrencyFormatter: FC<CurrencyFormatterProps> = ({ amount, currency }) => {

    const localeMap: any = {
        "THB": "th-TH",
        "VND": "vi-VN",
    };

    const locale = localeMap[currency];

    const maximumFractionDigits = currency == "THB" ? 2 : 0;

    const formattedAmount = amount.toLocaleString(locale, { style: 'currency', currency: currency, maximumFractionDigits: maximumFractionDigits });
    return <span>{formattedAmount}</span>;
};


type TimerCountProps = {

    payExpiredTime: number,
    onExpired: () => void
}

const TimerCount: FC<TimerCountProps> = ({ payExpiredTime, onExpired }) => {

    const [time, setTime] = useState(payExpiredTime - new Date().getTime());

    useEffect(() => {

        const timer = setInterval(() => {

            const value = payExpiredTime - new Date().getTime()

            setTime(() => value);

            if (value <= 0) {


                onExpired()

                clearInterval(timer);

            }


        }, 1000);

        return () => {
            clearInterval(timer);
        }

    }, [payExpiredTime]);


    return (
        <div style={{ fontSize: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span style={{ color: "red" }}>
                {time <= 0 ? "00:00" : dayjs(time).format("mm:ss")}
            </span>
        </div>

    )

}


export default () => {

    const { id } = useParams();

    // console.log("id=>", id);

    const [order, setOrder] = useState<any>(null);

    const [matchLoading, setMatchLoading] = useState<any>(false);

    const client = useRef<Ws>();

    const [text, setText] = useState<any>();

    const [chatLogs, setChatLogs] = useState<any[]>([]);

    const [expired, setExpired] = useState<boolean>(false);

    const [errorMsg, setErrorMsg] = useState<any>(null);

    const qrRef = useRef<any>();


    const lang = getLang(order?.currency);


    const copyText = (text: any) => {

        navigator.clipboard.writeText(text).then(() => {

            Toast.show(lang("复制成功"))
        });
    }

    const downloadQRCode = () => {
        setTimeout(() => {
            toPng(qrRef.current)
                .then((dataUrl) => {

                    // 创建一个 Blob 对象
                    const byteString = atob(dataUrl.split(',')[1]);
                    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);
                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([ab], { type: mimeString });

                    // 创建一个对象URL
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    // link.href = dataUrl;
                    link.href = url;
                    link.download = `qrcode-${order.id}.png`;
                    // 触发下载
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    // window.URL.revokeObjectURL(url);

                    // link.href = dataUrl;
                    //  window.location.href = dataUrl;

                    // document.body.appendChild(link);
                    // link.click();
                    // document.body.removeChild(link);
                })
                .catch((err) => {
                    console.error('Error generating QR code image:', err);
                });
        }, 10);

    };




    const loadOrder = (id: any) => {

        api(`api/chat/order/${id}`).get({}).then(ret => {
            if (ret.code != 200) {

                setErrorMsg(ret.msg);
                return;
            }

            if (ret.data?.expireTime <= new Date().getTime()) {
                setExpired(() => true);
            }

            setOrder(() => ret.data);


        }).catch(() => {

            setErrorMsg("request error")

        })

    }



    const matchAccount = () => {
        setMatchLoading(true);
        api(`api/chat/match/${id}`).post({}).then(ret => {
            if (ret.code == 200) {
                setOrder(ret.data);


                client?.current?.sendMessage({
                    msgType: "matchAccountSuccess",
                    data: {}
                });

                if (ret.data.receiveType == 100) {

                    Modal.alert({

                        title: lang("支付流程"),
                        content: (
                            <div>
                                <div>1、{lang("复制账号和金额转账")}</div>
                                <div>2、{lang("付款完成后请上传您的银行转账凭证")}</div>
                                <div>3、{lang("请耐心等待客服确认")}</div>
                                <div>4、{lang("不要私自保存收款账号")}</div>
                            </div>
                        ),
                        confirmText: lang("确定"),
                        showCloseButton: true,
                        closeOnMaskClick: true,

                    })
                }




            }
            if (ret.code == 201) {

                Modal.alert({
                    title: lang("提示"),
                    content: lang("获取失败，请重试"),
                    confirmText: lang("确定"),
                    closeOnMaskClick: true,
                    showCloseButton: true,
                })
            }

            if (ret.code == 400 || ret.code == 500) {
                Toast.show({
                    content: lang("获取失败，请重试")
                })
            }

        }).finally(() => {
            setMatchLoading(false);
        })
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight - 20
                // behavior: 'smooth' // 平滑滚动
            });
        }, 1)
    }


    useEffect(() => {

        // console.log("init chat=>")

        loadOrder(id);

    }, []);

    if (errorMsg) {

        return (
            <ConfigProvider locale={thTH}>
                <ErrorBlock
                    title="error"
                    description={errorMsg}
                />
            </ConfigProvider>
        )
    }

    if (order == null) {

        return (
            <div className="loading">
                <DotLoading />
            </div>
        )
    }




    //支付成功
    if (order && order.orderStatus == 1) {

        return (

            <ConfigProvider locale={thTH}>
                <div className="result-page">
                    <Result
                        status="success"
                        title={lang("支付成功")}
                    />
                </div>
            </ConfigProvider>
        )

    }


    //订单过期
    if (expired) {


        return (
            <div className="result-page">
                <Result status="error" title={lang("订单已过期")} description=""
                />
            </div>

        )

    }






    return (

        <ChatSocket
            url={`wss:${baseApiUrl}/ws`}
            wsRef={client}
            onOpen={ws => {
                console.log("onOpen=>", ws);
                ws.sendMessage({
                    msgType: "auth",
                    data: {
                        orderNo: id
                    }
                });
            }}

            onMessage={(ws, message) => {

                console.log("message=>", message);

                const { msgType, data } = message;
                if (msgType == "order") {
                    setOrder(() => data);

                    if (data.expireTime <= new Date().getTime()) {
                        setExpired(() => true);
                    }

                    return;
                }

                if (msgType == "text-message") {

                    // console.log("chatLogs=>",chatLogs);

                    // addChatLog(data);

                    setChatLogs((preLogs) => [
                        ...preLogs,
                        data
                    ])

                    // setChatLogs([
                    //     ...chatLogs,
                    //     ...[{...data}]
                    // ]);
                    // scrollToBottom();
                    return;
                }

                if (msgType == "loadChatLogs") {

                    // addChatLogAll(data?.logs)

                    setChatLogs(() => data?.logs)

                    // scrollToBottom();
                    return;
                }
                if (msgType == "uploadSuccess") {

                    // addChatLogAll(data?.logs)

                    // setChatLogs(() => data?.logs)

                    setChatLogs((preLogs) => [
                        ...preLogs,
                        data
                    ]);

                    scrollToBottom();
                    return;
                }

            }}

            onClose={(ws, msg) => {

            }}
        >
            <ConfigProvider locale={thTH}>


                {order == null ? (
                    <div className="loading">
                        <DotLoading />
                    </div>
                ) : (
                    <div id="chat-page">

                        <NavBar back={null} className="navbar">
                            <TimerCount payExpiredTime={order.expireTime} onExpired={() => {

                                // console.log("订单过期")

                                setExpired(true);

                            }} />
                        </NavBar>
                        <div className="chat-order">

                            <List >
                                <List.Item title={lang("订单号")} extra={order.id}>
                                </List.Item>

                                <List.Item title={lang("金额")} extra={(

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ fontSize: 24, color: "red" }}><CurrencyFormatter amount={parseFloat(order.amount)} currency={order.currency} /></span>
                                        {(order.matchMch == 1 && order.receiveType == 100) ? (
                                            <a className="copy-btn" onClick={() => copyText(`${parseFloat(order.amount)}`)} >{lang("复制")}</a>
                                        ) : null}

                                    </div>
                                )}>

                                </List.Item>
                                {order.matchMch == 0 ? (<>

                                    <List.Item>
                                        <div>
                                            <Button loading={matchLoading} color="primary" onClick={matchAccount} block>{lang("点击获取付款信息")}</Button>
                                        </div>
                                    </List.Item>
                                </>
                                ) : (
                                    <>
                                        {order.receiveType == 100 ? (
                                            <>
                                                <List.Item arrow title={lang("银行")} extra={
                                                    <div>
                                                        <span>{order.accountType}</span>

                                                    </div>
                                                } ></List.Item>
                                                <List.Item title={lang("姓名")} extra={
                                                    <div>
                                                        <span className="acc-value">{order.accountName}</span>
                                                        <a className="copy-btn" onClick={() => copyText(`${order.accountName}`)}>{lang("复制")}</a>
                                                    </div>
                                                }>

                                                </List.Item>
                                                <List.Item title={lang("卡号")} extra={
                                                    <div>
                                                        <span className="acc-value">{order.accountNo}</span>
                                                        <a className="copy-btn" onClick={() => copyText(`${order.accountNo}`)} >{lang("复制")}</a>
                                                    </div>
                                                }></List.Item>

                                                {order.currency == "VND" ? (
                                                    <List.Item title={lang("留言")}
                                                        extra={
                                                            <div>
                                                                <div>


                                                                    <span className="acc-value">{`${order.id}`.substring(12)}</span>
                                                                    <a className="copy-btn" onClick={() => copyText(`${order.id}`.substring(12))} >{lang("复制")}</a>
                                                                </div>

                                                            </div>
                                                        }
                                                    ></List.Item>
                                                ) : null}

                                            </>
                                        ) : null}

                                        {order.receiveType == 101 ? (
                                            <>
                                                <List.Item style={{
                                                    "--prefix-width": "0px",

                                                }}
                                                    description={(
                                                        <div style={{ width: "100%" }}>
                                                            <div  >
                                                                <div ref={qrRef} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 0, flexDirection: "column", backgroundColor: "#fff", paddingTop: 15 }}>
                                                                    <QRCodeCanvas
                                                                        size={150}
                                                                        value={generatePayload(order.accountNo, {
                                                                            amount: order.amount
                                                                        })}

                                                                    />
                                                                    <div style={{ textAlign: "center", marginTop: 10 }}>
                                                                        Order No：{order.id} Amount：{order.amount}
                                                                    </div>
                                                                    <div style={{ textAlign: "center", marginBottom: 10, fontSize: 14, color: "red" }}>
                                                                        QR Code สามารถชำระเงินได้เพียงครั้งเดียวเท่านั้น กรุณาอย่าบันทึกรหัส QR เพื่อชำระเงินซ้ำ!
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div style={{ textAlign: "center", marginBottom: 10, fontSize: 14 }}>

                                                                <a onClick={downloadQRCode}>{lang("下载二维码")}</a>

                                                            </div>
                                                            <div style={{ textAlign: "center", marginBottom: 15, fontSize: 14, color: "red" }}>
                                                                หลังจากการโอนเงินเสร็จสิ้น โปรดอัปโหลดภาพหน้าจอการโอนเงินผ่านธนาคาร ไม่เช่นนั้นการชำระเงินจะไม่สำเร็จ
                                                            </div>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <img style={{ width: "100%" }} src="https://kabank.oss-ap-southeast-1.aliyuncs.com/upload/image/2024/06/04/f3c3a038e0f84defb8c933c352ac5661.png" />
                                                            </div>
                                                        </div>
                                                    )}
                                                >


                                                </List.Item>
                                            </>
                                        ) : null}

                                        {order.receiveType == 102 ? (
                                            <>
                                                <List.Item arrow title={"Phương thức"} extra={
                                                    <div>
                                                        <span>Ví điện tử MOMO</span>

                                                    </div>
                                                } ></List.Item>
                                                {/* <List.Item title={lang("姓名")} extra={
                                                    <div>
                                                        <span className="acc-value">{order.accountName}</span>
                                                        <a className="copy-btn" onClick={() => copyText(`${order.accountName}`)}>{lang("复制")}</a>
                                                    </div>
                                                }>

                                                </List.Item> */}
                                                <List.Item title={"Ví điện tử MOMO"} extra={
                                                    <div>
                                                        <span className="acc-value">{order.accountNo}</span>
                                                        <a className="copy-btn" onClick={() => copyText(`${order.accountNo}`)} >{lang("复制")}</a>
                                                    </div>
                                                }></List.Item>

                                                <List.Item title={lang("留言")}
                                                    extra={
                                                        <div>
                                                            <div>


                                                                <span className="acc-value">{`${order.id}`.substring(12)}</span>
                                                                <a className="copy-btn" onClick={() => copyText(`${order.id}`.substring(12))} >{lang("复制")}</a>
                                                            </div>

                                                        </div>
                                                    }
                                                ></List.Item>

                                            </>
                                        ) : null}


                                        <List.Item title={
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ color: "red", fontSize: 20 }} >*</span>
                                                    <span style={{ marginLeft: 5 }}>{lang("上传付款凭证")}</span>
                                                </div>
                                                <div>
                                                    {order.receiveType == 100 ? (
                                                        <>
                                                            <span style={{ color: "red", fontSize: 12, marginLeft: 10 }}>{lang("付款完成后请上传您的银行转账凭证")}</span>
                                                        </>
                                                    ) : null}
                                                    {order.receiveType == 101 ? (
                                                        <>
                                                            <span style={{ color: "red", fontSize: 12, marginLeft: 10 }}>{lang("付款完成后请上传您的银行转账凭证")}</span>
                                                        </>
                                                    ) : null}
                                                    {order.receiveType == 102 ? (
                                                        <>
                                                            <span style={{ color: "red", fontSize: 12, marginLeft: 10 }}>
                                                                Sau khi thanh toán hoàn tất, vui lòng tải lên ảnh chụp màn hình chứng từ chuyển khoản của bạn.
                                                            </span>
                                                        </>
                                                    ) : null}

                                                </div>
                                            </div>
                                        } extra={
                                            <div style={{ padding: 10 }}>
                                                <UploadImage
                                                    currency={order.currency}
                                                    value={order?.voucherUrl}
                                                    onChange={(img: any) => {
                                                        setOrder({
                                                            ...order,
                                                            voucherUrl: img
                                                        });

                                                        if (img) {

                                                            client.current?.sendMessage({
                                                                msgType: "uploadVoucher",
                                                                data: {
                                                                    voucherUrl: img
                                                                }
                                                            });

                                                            // scrollToBottom();

                                                        }

                                                    }}
                                                />



                                            </div>
                                        }>
                                            <div>
                                                <div style={{ textAlign: "left" }}>

                                                </div>
                                            </div>
                                        </List.Item>
                                    </>
                                )}
                            </List>
                        </div>

                        {order.matchMch == 1 ? (
                            <>

                                <div className="chat-log">
                                    <Card title={lang("聊天")}>

                                        <div className="chat-container">
                                            <div className="chat-box">
                                                {chatLogs?.map((msg, i) => {

                                                    if (msg.sendType == 0) {
                                                        return (
                                                            <div className="message user-message" key={`u-${i}`}>
                                                                <div className="message-content">
                                                                    {msg.message}
                                                                </div>
                                                                <div className="message-time">{dayjs(msg.createTime).format("HH:mm:ss")}</div>
                                                            </div>
                                                        )
                                                    }

                                                    return (
                                                        <div className="message friend-message" key={`f-${i}`}>
                                                            <div className="message-content">
                                                                {msg.message}
                                                            </div>
                                                            <div className="message-time" style={{ alignSelf: "flex-start" }}>{dayjs(msg.createTime).format("HH:mm:ss")}</div>
                                                        </div>
                                                    )


                                                })}


                                            </div>

                                        </div>
                                    </Card>
                                </div >

                                {order.chat == 1 ? (
                                    <>
                                        <div className="chat-footer" >
                                            <div className="chat-footer-l">

                                                <div className="send-text">
                                                    <TextArea style={{ width: "auto", paddingRight: 70 }} autoSize={{ minRows: 1, maxRows: 5 }} value={text} onChange={text => setText(text)} placeholder={lang("请输入消息")} rows={1} />
                                                </div>

                                                <div className="btn-send" onClick={() => {

                                                    client.current?.sendMessage({
                                                        msgType: "text-message",
                                                        data: {
                                                            text: `${text}`
                                                        }
                                                    });

                                                    chatLogs.push({
                                                        sendType: 0,
                                                        message: `${text}`,
                                                        createTime: new Date().getTime(),
                                                    });

                                                    setChatLogs([...chatLogs]);

                                                    setText("");

                                                    scrollToBottom();

                                                }}>
                                                    <Button color="primary"  >{lang("发送")}</Button>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                ) : null}

                            </>
                        ) : null}

                    </div >
                )
                }
            </ConfigProvider>

        </ChatSocket >

    )
}